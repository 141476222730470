@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
body {
  font-family: 'Open Sans', sans-serif;
  background: #f9faff;
  color: #3a3c47;
  line-height: 1.6;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
}

.App {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100vw;
  min-height: 100vh;
  background: linear-gradient(to bottom right, rgba(145, 228, 247, 0.8), rgba(181, 234, 215, 0.8) 30%, rgba(252, 227, 138, 0.8) 60%, rgba(243, 129, 129, 0.8) 98%);

}

.loginContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25em;
  max-height: 120vh;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  padding: 2em;
  box-sizing: border-box;
  overflow: auto;
}

@media (max-width: 767px) {
  .loginContainer {
    align-items: flex-start;
    margin-top: 5vh; 
    margin:1vh;
    
  }
  .App{
    background: white;
  }

    .loginBut {
      margin-top: 10px;
      align-self: center;
      height: 3em;
      width: 10em;
    }
  
  

}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 10px;
  word-wrap: break-word;
}
.input-container input{
  height: 1.5em;
  width: 15em;
  border-radius:10 px;
  border-width: 15px;
  border: none;
  border-bottom: 2px solid rgba(163, 162, 162, 0.8);
  padding-top: 20px;
  outline: none;
  padding:10px;
  font-size: 20px;
  
}
.input-container input:focus {
  border-bottom: 2px solid rgb(160, 157, 157);
}
.center {
  text-align: center;
}


.referral-code {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  word-break: break-word;
}

input:focus{   
  
  outline: 0 none;
}
a{
  color: rgb(0, 68, 255);
  font-weight: 500;
  margin: 5px;
  cursor: pointer;
  align-self: baseline;
  margin-left: 40px;
}
.loginBut {
  height: 4em;
  width: 14em;
  margin: 10px;
  margin-top: 25px;
  border-radius: 15px;
  background: rgb(244, 100, 100);
  border: none;
  transition: all .2s ease-in-out;
  font-weight: bold;
}
.loginBut:hover{

  box-shadow: 0 0 12px rgb(83, 83, 83);
}
.loginBut p{
  color:rgb(255, 255, 255);
  font-size: 22px;
  margin-top: 13px;
}
.siG{
  display: flex;
  align-items: center;
  justify-content: center;
  gap:18px;
  height: 4em;
  width: 24em;
  margin: 10px;
  border-radius: 15px;
  background-color: rgb(255, 255, 255);
  border-color: rgb(152, 145, 255);
  border-width: 3px;
  border-style: dashed;
  transition: all .2s ease-in-out;
}
.siG:hover{
  transform: scale(1.1);
  box-shadow: 0 0 12px rgb(199, 199, 199);
  cursor: pointer;
}
.siG p{
  font-size: 20px;
}

@font-face {
  font-family: 'RolexLight';
  src: url('/fonts/RolexLight.woff2') format('woff2'),
      
}
@media (max-width: 768px) {
  .logo {
    display: block;
    margin: 0 auto;
  }
}
